import React from "react"
import {Link} from 'gatsby';
import Layout from "../../components/bluescope/layout"
import seologo from "../../images/bluescope/appicon256.png"
import Seo from "../../components/SEO"

function BlueScopePrivacyPage(props) {

    const seoImage = {
		src: seologo,
		height: 256,
		width: 256,
		alt: "BlueScope for iOS app logo",
    };

    const appStoreCampaignUrlBlueScope = "https://apps.apple.com/app/apple-store/id1530096924?pt=121290356&ct=malaudhome&mt=8"

    return (
    <Layout>
        <Seo
            siteTitle={'BlueScope® Privacy Policy'}
            seoTitle='BlueScope Privacy Policy'
            path={props.location.pathname}
            metaImage={seoImage}
            icon={seologo}
            description='BlueScope® for iOS - Privacy Policy'
            keywords={["BlueScope", "BlueScope Privacy Policy"]}
            datePublished="12-13-2020"
            dateModified="06-26-2024"
        />
        
        <div className={"container"} style={{textAlign: 'left'}}>
            <div className={"content"}>
                <div className={"title"}>
                    <a href={appStoreCampaignUrlBlueScope} target={"_blank"} rel="noreferrer" style={{textDecoration: 'None', color: '#0398F4'}} title={"BlueScope for iOS"}>
                        <h1 style={{textDecoration: 'None', color: '#0398F4'}}>BlueScope® for iOS</h1>
                    </a>

                    <h2>Privacy Policy</h2>
                </div>

                <div>
                <h6 style={{textAlign: 'left'}}>
                    Last Updated: June 26, 2024
                </h6>
                </div>

                <p>
                    We value your privacy and believe that everyone has the right to protect their personal data.
               </p>
               <h4>We do not track our users.</h4>
               <p>
                    This page describes the privacy policy for <a href={appStoreCampaignUrlBlueScope} target={"_blank"} rel="noreferrer" style={{textDecoration: 'None', color: '#0398F4'}} title={"BlueScope for iOS"}>BlueScope® for iOS</a> app. To read about the privacy policy for this website, please visit <Link to="/privacy" style={{textDecoration: 'None', color: '#0398F4'}}>this link</Link>.
                </p>

                <h3>Data we collect and how it's used</h3>
                <p>
                    <span style={{fontWeight: 'bold'}}>Identifiers: </span>App based device identifiers may be collected and used for app functionality such as verifying any in-app purchase transactions. These identifiers are not linked to you and do not contain any personal information.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Diagnostic Data: </span>Diagnostic data, such as crash logs, may be used to diagnose problems and improve the app. The diagnostic data is not linked to you and does not contain any personal information.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Support Data: </span>When you contact support, you provide your email, name and other information that may be relevant to your support query. This information will be stored on our servers and for the sole purpose of answering your support request and for legal & administrative purposes.
                </p>

                <br />

                <h3>Data we do not collect</h3>

                <p style={{padding: 0}}>
                    <span style={{fontWeight: 'bold'}}>Personally Identifiable Data: </span> Any personally identifiable data is not collected, such as user name, email, phone number, address, contacts etc.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Bluetooth® Device Data: </span> BlueScope® attempts to gather basic data about nearby Bluetooth® devices. This data is used to display information about discovered Bluetooth® devices within the app. When devices are moved to saved list, this data is stored on the user's device for later display within the app. This data is not collected or shared with any third parties.
                </p>

                <p>
                    <span style={{fontWeight: 'bold'}}>Bluetooth® Device Location Data: </span> When location services are enabled, the location of the nearby Bluetooth® devices is also stored on the device, if available. This information is used to display the last location of the discovered Bluetooth® devices. This location data is not collected or shared with any third parties.
                </p>

                <br />
                <hr style={{width: '30%'}}></hr>
                <br />
                {/* <p>---</p> */}

                {/* <h3>Consent</h3> */}
                <p>
                    By using our website, you hereby consent to our website <Link to="/privacy" style={{textDecoration: 'None', color: '#0398F4'}}>Privacy Policy</Link>.
                    If you have additional questions or require more information, please <Link to="/contact" style={{textDecoration: 'None', color: '#0398F4'}}>contact us</Link>.
                </p>

            </div>
        </div>

        <div className={"container"}>
            <div className={"notes"}>
                <ul>
                    <li>BlueScope® is a registered trademark of Malaud LLC.</li>
                    <li>Bluetooth® is a registered trademark of Bluetooth SIG.</li>
                    <li>
                        Apple®, iOS®, iPhone®, iPad®, App Store®, Apple Watch®, Apple Maps®, AirPods®, Siri® are registered trademarks of Apple Inc.
                    </li>
                </ul>      
            </div>
        </div>

    </Layout>
    )
}

export default BlueScopePrivacyPage
