/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {StaticQuery, graphql, Link} from "gatsby"

import "../../css/bluescope/main.scss"

const Layout = ({children}) => (
    <StaticQuery query={graphql`
      query BSSiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `} render={data => (
        <>
            {/* <Header siteTitle={data.site.siteMetadata.title}/> */}
            <main>{children}</main>
            <footer>
                <div className={"container"}>
                    <div className={"row"}>

                        {/* <div className={"col-2"}> */}
                            <div className={"widget__item"}>
                                <div className={"footer-links"}>
                                    <h4>Support</h4>
                                    <ul>
                                        <li><Link to="/bluescope" style={{textDecoration: 'None', color: '#0398F4'}}>BlueScope Home</Link></li>
                                        <li><Link to="/bluescope/terms" title={"Terms Of Use"} style={{textDecoration: 'None', color: '#0398F4'}}>BlueScope Terms Of Use</Link></li>
                                        <li><Link to="/bluescope/privacy" title={"Privacy Policy"} style={{textDecoration: 'None', color: '#0398F4'}}>BlueScope Privacy</Link></li>
                                        <li><Link to="/bluescope/support" title={"Frequently Asked Questions"} style={{textDecoration: 'None', color: '#0398F4'}}>BlueScope FAQ</Link></li>
                                        <li><Link to="/contact" title={"Contact Us"} style={{textDecoration: 'None', color: '#0398F4'}}>Contact Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>

                    <div className={"copyright"}>
                        <p>&copy; {new Date().getFullYear()}, {` `} 
                        <a href="https://malaud.com" style={{textDecoration: 'None', color: '#0398F4'}} title={"Malaud"}>Malaud LLC</a>. All rights reserved.</p>
                    </div>
                    
                </div>

            </footer>
        </>
    )}/>
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
